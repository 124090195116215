import { ConfigProvider } from "antd";
import RouteSetup from "./router";

const App: React.FC = () => (
  <>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#015B60",
          borderRadius: 4,
          colorBorder: "#89a6af",
        },
      }}
    >
      <RouteSetup />
    </ConfigProvider>
  </>
);

export default App;
